import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, subDays } from 'date-fns';
import { APIError, APIStatus } from '../../@types/APIStatus';
import { OnlineMarketingFilterBarState } from '../../@types/filterBars';
import { SortBy } from '../../@types/filters';
import { OnlineMarketingState, Pagination } from '../../@types/reduxStates';
import { OnlineMarketingReponse } from '../../@types/responsesAPI';
import axios from '../../utils/axios';

const initialState: OnlineMarketingState = {
  filters: {
    startDate: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    endDate: {
      startFilter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      endFilter: format(subDays(new Date(), 1), 'yyyy-MM-dd')
    },
    groupBy: 0,
    columns: [],
    channel: [],
    campaign: [],
    adGroupName: [],
    campaignType: [],
    campaignStatus: [],
    dailyBudgetFrom: 0,
    dailyBudgetTill: 0,
    impressionsFrom: 0,
    impressionsTill: 0,
    clicksFrom: 0,
    clicksTill: 0,
    conversionsFrom: 0,
    conversionsTill: 0
  },
  response: {
    rows: [],
    total: {
      channel: '',
      campaignName: '',
      campaignId: 0,
      adGroupName: '',
      adGroupId: 0,
      campaignType: '',
      dailyBudget: 0,
      startDate: '',
      endDate: '',
      impressions: 0,
      clicks: 0,
      ctr: 0,
      cpc: 0,
      throughCpc: 0,
      cpm: 0,
      ecpm: 0,
      conversion: 0,
      convValue: 0,
      costConv: 0,
      acos: '',
      roas: '',
      status: ''
    },
    totalCount: 0
  },
  APIStatus: APIStatus.IDLE
};

const slice = createSlice({
  name: 'onlineMarketing',
  initialState,
  reducers: {
    setFilters: (
      state: any,
      action: PayloadAction<OnlineMarketingFilterBarState>
    ) => {
      state.filters = action.payload;
    },
    getOnlineMarketing: (state: any) => {
      state.APIStatus = APIStatus.PENDING;
      state.error = undefined;
    },
    getOnlineMarketingSuccess: (
      state: any,
      action: PayloadAction<OnlineMarketingReponse>
    ) => {
      state.response = action.payload;
      state.APIStatus = APIStatus.FULFILLED;
    },
    getOnlineMarketingError: (state: any, action: PayloadAction<APIError>) => {
      state.APIStatus = APIStatus.REJECTED;
      state.error = action.payload;
    }
  }
});

export function fetchOnlineMarketingFilters(
  filters: OnlineMarketingFilterBarState
) {
  return async (dispatch: any) => {
    dispatch(setFilters(filters));
  };
}

export function fetchOnlineMarketing(
  filterBar: OnlineMarketingFilterBarState,
  { skip, limit }: Pagination,
  { by, order }: SortBy,
  abortController: AbortController
) {
  return async (dispatch: any) => {
    dispatch(getOnlineMarketing());
    try {
      const params = {
        skip,
        limit,
        by,
        order,
        startFrom: filterBar.startDate?.startFilter,
        startTill: filterBar.startDate?.endFilter,
        endFrom: filterBar.endDate?.startFilter,
        endTill: filterBar.endDate?.endFilter,
        groupBy: filterBar.groupBy ? filterBar.groupBy : undefined,
        channel:
          filterBar.channel.length > 0
            ? filterBar.channel.map((c) => c.id).join(',')
            : undefined,
        // TODO: Type new filter types
        campaignName:
          filterBar.campaign.length > 0
            ? filterBar.campaign.map((c) => c.id).join(',')
            : undefined,

        adGroupName: [],
        campaignType: [],
        campaignStatus: [],
        dailyBudgetFrom: filterBar.dailyBudgetFrom ?? undefined,
        dailyBudgetTill: filterBar.dailyBudgetTill ?? undefined,
        impressionsFrom: filterBar.impressionsFrom ?? undefined,
        impressionsTill: filterBar.impressionsTill ?? undefined,
        clicksFrom: filterBar.clicksFrom ?? undefined,
        clicksTill: filterBar.clicksFrom ?? undefined,
        conversionsFrom: filterBar.conversionsFrom ?? undefined,
        conversionsTill: filterBar.conversionsTill ?? undefined
      };
      const response = await axios.get('/api/v1/onlineMarketing', {
        params,
        signal: abortController.signal
      });
      dispatch(getOnlineMarketingSuccess(response.data));
    } catch (error) {
      dispatch(getOnlineMarketingError(error as APIError));
    }
  };
}

export const {
  setFilters,
  getOnlineMarketing,
  getOnlineMarketingError,
  getOnlineMarketingSuccess
} = slice.actions;

export default slice.reducer;
