import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ProductListFilterBarState } from '../@types/filterBars';
import { SortBy } from '../@types/filters';
import { Pagination } from '../@types/reduxStates';
import { RootState } from '../redux/rootReducer';
import { fetchProductItems } from '../redux/slices/productItems';
import { TableHeaderCell } from '../@types/tableRows';
import useLocales from './useLocales';
import { divideIfNotZero, unknownToNumber } from '../utils/helper';
import { useConstants } from '../ConstantsContext';

function useVariants() {
  const { WIDTH_PRODUCT_CELL } = useConstants();
  const { currentLang } = useLocales();
  const dispatch = useDispatch();
  const {
    response: {
      rows,
      count,
      stock,
      avgPrice,
      orders,
      revenue,
      margin,
      returns,
      activityNormal,
      activityCritical
    },
    filters,
    APIStatus,
    error
  } = useSelector((state: RootState) => state.productItems);
  const { t: translate } = useTranslation(['component']);

  const activeMarketIdsSize = useMemo(() => {
    const am = new Set<number>();
    rows.forEach((r) => {
      r.activeMarketsData.forEach((aMarket) => am.add(aMarket.marketId));
    });
    return am.size;
  }, [rows]);

  const summary: Array<TableHeaderCell> = useMemo(
    () => [
      {
        id: 0,
        field: 'product',
        label: translate('component:article'),
        align: 'left',
        canSort: true,
        minWidth: WIDTH_PRODUCT_CELL
      },
      {
        id: 1,
        field: 'stock',
        label: translate('stock'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.stock)
            .reduce((prev, next) => prev + next, 0),
          value2: stock,
          align: 'left',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 2,
        field: 'avPrice',
        label: translate('av_price'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: divideIfNotZero(
            rows.map((r) => r.avgPrice).reduce((prev, next) => prev + next, 0),
            rows.length
          ),
          value2: avgPrice,
          align: 'center',
          paddingRight: '8px',
          calculation: 'average',
          type: 'currency'
        }
      },
      {
        id: 3,
        field: 'orders',
        label: translate('orders'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.orders)
            .reduce((prev, next) => prev + next, 0),
          value2: orders,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 4,
        field: 'revenue',
        label: translate('net_revenue'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.revenue)
            .reduce((prev, next) => prev + next, 0),
          value2: revenue,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'currency'
        }
      },
      {
        id: 5,
        field: 'margin',
        label: translate('margin'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.margin)
            .reduce((prev, next) => prev + next, 0),
          value2: margin,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'currency'
        }
      },
      {
        id: 6,
        field: 'returns',
        label: translate('return'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: rows
            .map((r) => r.returns)
            .reduce((prev, next) => prev + next, 0),
          value2: returns,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 7,
        field: 'activity',
        label: translate('activity'),
        align: 'center',
        minWidth: 160,
        canSort: false,
        summary: {
          value: `(${activityNormal}/${activityCritical})`,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 8,
        field: 'recommendations',
        label: translate('recommendations'),
        align: 'center',
        minWidth: 160,
        canSort: false,
        summary: {
          value: `(0/0)`,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum'
        }
      },
      {
        id: 9,
        field: 'activeMarketsData',
        label: translate('active_markets'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: activeMarketIdsSize,
          align: 'center',
          paddingRight: '8px',
          calculation: 'sum',
          type: 'integer'
        }
      },
      {
        id: 10,
        field: 'salesRank',
        label: translate('sales_rank'),
        align: 'center',
        minWidth: 160,
        canSort: true,
        summary: {
          value: 0,
          type: 'no'
        }
      },
      {
        id: 11,
        field: 'productHealth',
        label: translate('product_health'),
        align: 'right',
        minWidth: 160,
        canSort: true,
        summary: {
          value: 0,
          type: 'no'
        }
      }
    ],
    [currentLang, activeMarketIdsSize]
  );

  const abortController = useRef<AbortController>(new AbortController());

  const cancelFetch = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  const getProducts = (
    filters: ProductListFilterBarState,
    pagination: Pagination,
    sortBy: SortBy,
    variant?: string
  ) => {
    cancelFetch();
    dispatch(
      fetchProductItems(
        filters,
        pagination,
        sortBy,
        abortController.current,
        variant
      )
    );
  };

  return {
    rows,
    count,
    stock,
    avgPrice,
    orders,
    revenue,
    margin,
    returns,
    activityNormal,
    activityCritical,
    filters,
    APIStatus,
    error,
    summary,
    cancelFetch,
    getProducts
  };
}

export default useVariants;
